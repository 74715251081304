import { createContext, useContext, useState, useEffect } from 'react';

const FunnelDataContext = createContext();

const FunnelDataProvider = ({ funnelData: initialFunnelData, pagePath, children }) => {
  const [funnelData, setFunnelData] = useState(initialFunnelData);
  const [currentPath, setCurrentPath] = useState(pagePath);

  // Reset state for new path to avoid upsell pages maintaining initial funnel data from the first upsell.
  // This may not occur before the first render of other components; they may see
  // the previous page's funnelData for initial render before this updates and causes
  // another render.
  useEffect(() => {
    if (pagePath !== currentPath) {
      setFunnelData(initialFunnelData);
      setCurrentPath(pagePath);
    }
  }, [currentPath, pagePath, initialFunnelData]);

  return (
    <FunnelDataContext.Provider value={{ ...funnelData }}>{children}</FunnelDataContext.Provider>
  );
};

const useFunnelData = () => {
  const context = useContext(FunnelDataContext);
  if (context === undefined) {
    throw new Error('useFunnelData must be used within a FunnelDataProvider');
  }

  return context;
};

export { FunnelDataProvider, useFunnelData };
