import { useRouter } from 'next/router';
import { useFunnelData } from './funnel-data-context';
import { buildFunnelPath } from './url';

const excludedParams = {
  funnelVariantId: 'funnelVariantId',
  fid: 'fid',
  slug: 'slug',
  path: 'path',
  query: 'query',
  tag: 'tag',
  page: 'page',
};

export function useGetPathWithQueryParams(url) {
  const router = useRouter();
  const { funnelId } = useFunnelData();

  const queryParams = router.query;

  for (const item in excludedParams) {
    delete queryParams[item];
  }

  return buildFunnelPath(url, funnelId, queryParams);
}

export function addQueryParamsToUrl(url, queryParams) {
  for (const item in excludedParams) {
    delete queryParams[item];
  }

  const queries = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('');

  const separator = queries ? (url.includes('?') ? '&' : '?') : '';

  return `${url}${separator}${queries}`;
}
