import PropTypes from 'prop-types';
import { useTheme } from 'utils/theme-context';
import { Themes } from 'constant';

export const queryTemplateForPolicyPages = [
  'primaryColor',
  'secondaryColor',
  'headingColorDark',
  'headingColorLight',
  'textColorDark',
  'textColorLight',
  'backgroundColor',
  'fontSize',
  { headingFont: ['family'] },
  { textFont: ['family'] },
];

export const queryTemplate = [
  'colorPositive',
  'colorNegative',
  'starColor',
  'starEmptyColor',
  ...queryTemplateForPolicyPages,
];

const propTypes = {
  fonts: PropTypes.objectOf(
    PropTypes.shape({
      fontFamily: PropTypes.string.isRequired,
      weights: PropTypes.shape({
        normal: PropTypes.number.isRequired,
        bold: PropTypes.number.isRequired,
        black: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  headingColorDark: PropTypes.string.isRequired,
  headingColorLight: PropTypes.string.isRequired,
  textColorDark: PropTypes.string.isRequired,
  textColorLight: PropTypes.string.isRequired,
  colorPositive: PropTypes.string,
  colorNegative: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
  tagColor: PropTypes.string,
  linkColor: PropTypes.string,
  fontSize: PropTypes.string.isRequired,
  headingFont: PropTypes.shape({ family: PropTypes.string.isRequired }).isRequired,
  textFont: PropTypes.shape({ family: PropTypes.string.isRequired }).isRequired,
};

const defaultProps = {
  // Not all pages use all global style props
  colorPositive: null,
  colorNegative: null,
  starColor: null,
  starEmptyColor: null,
  tagColor: null,
  linkColor: '#8d5a8c',
};

function GlobalStyle({
  fonts,
  primaryColor,
  secondaryColor,
  headingColorDark,
  headingColorLight,
  textColorDark,
  textColorLight,
  colorPositive,
  colorNegative,
  starColor,
  starEmptyColor,
  tagColor,
  fontSize,
  headingFont,
  textFont,
  linkColor,
}) {
  const { family: headingFontName } = headingFont;
  const { family: textFontName } = textFont;

  const { theme } = useTheme();

  let headingFontFamily = fonts[headingFontName].fontFamily;
  let textFontFamily = fonts[textFontName].fontFamily;
  if (process.env.NEXT_PUBLIC_USE_SYSTEM_FONTS === 'true') {
    headingFontFamily = 'var(--system-ui)';
    textFontFamily = 'var(--system-ui)';
  }

  return (
    <style jsx global>{`
      :root {
        --system-ui: system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        --primary-color: ${primaryColor};
        --secondary-color: ${secondaryColor};
        --heading-color-dark: ${headingColorDark};
        --heading-color-light: ${headingColorLight};
        --text-color-dark: ${textColorDark};
        --text-color-light: ${textColorLight};
        --text-color-grey-dark: #6a6a6a;
        --text-color-sea: #eaffed;
        --default-background-color: transparent;
        --background-color-grey: #f2f2f2;
        --color-positive: ${colorPositive};
        --color-negative: ${colorNegative};
        --tag-color: ${tagColor};
        --default-star-color: ${starColor};
        --default-star-empty-color: ${starEmptyColor};
        --border-color1: #333333;
        --border-color2: #707070;
        --link-text-color: ${linkColor};
        --default-checkout-button-color: #009c1b;
        --heading-font-family: ${headingFontFamily};
        --text-font-family: ${textFontFamily};
        --box-shadow-corner: 0px 3px 6px #00000029;
        --box-shadow-all: 0px 0px 6px 0 #00000029;
        --xs: 0.75rem;
        --sm: 0.875rem;
        --md: 1rem;
        --lg: 1.125rem;
        --xl: 1.25rem;
        --xl2: 1.5rem;
        --xl3: 1.875rem;
        --xl4: 2.25rem;
        --xl5: 3rem;
        --xl6: 3.75rem;
        --xl7: 4.5rem;
        --xl8: 6rem;
        --xl9: 8rem;
        --heading-weight-normal: ${fonts[headingFontName].weights?.normal};
        --heading-weight-bold: ${fonts[headingFontName].weights?.bold};
        --heading-weight-black: ${fonts[headingFontName].weights?.black};
        --text-weight-normal: ${fonts[textFontName].weights?.normal};
        --text-weight-bold: ${fonts[textFontName].weights?.bold};
        --text-weight-black: ${fonts[textFontName].weights?.black};
        --container-padding: 10px;
        --container-margin: ${theme === Themes.THEME_2 ? '35px' : '20px'};
        --container-margin-mobile: 10px;
        --input-field-height: 50px;
        --input-field-margin-bottom: 15px;
        --tagline-letter-spacing: 2px;
        --border-radius: 5px;
        --disabled-button-background-color: #e0dddd;
        /* 
        The following z-index values must be
        used wherever using z-index is needed.
        Notes on the values: 
          - The input one must be used when the
            floating label effect is intended.
          - The overlay one must be used if an element
            of part of it should cover another element or 
            when a container has a shadow effect.
          - The sticky and modal ones should be used
            for sticky elements and modal containers.
        */
        --z-index-input: 1;
        --z-index-overlay: 10;
        --z-index-sticky: 20;
        --z-index-modal: 30;
        --divider-color: #e6e6e6;
      }
      * {
        box-sizing: border-box;
      }
      html {
        font-size: ${fontSize}px;
        scroll-behavior: smooth;
      }
      html,
      body {
        font-family: var(--text-font-family);
        color: var(--text-color-dark);
        overflow-x: visible;
      }
      button {
        font-family: var(--text-font-family);
        color: var(--text-color-dark);
      }
      html,
      body,
      h1,
      h2,
      h3,
      h4,
      p {
        margin: 0;
        padding: 0;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: var(--heading-font-family);
      }
      h1 {
        font-size: var(--xl6);
      }
      h2 {
        font-size: var(--xl4);
      }
      h3 {
        font-size: var(--xl2);
      }
      h4 {
        font-size: var(--xl);
      }
      h5 {
        font-size: var(--lg);
      }
      p,
      li,
      a {
        font-size: var(--xl);
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    `}</style>
  );
}

GlobalStyle.propTypes = propTypes;
GlobalStyle.defaultProps = defaultProps;

export default GlobalStyle;
