import NextImage from 'next/image';
import { getDirectusFileUrl } from 'utils/directus';

const mapDirectusImage = data => {
  const { width, height, metadata } = data;
  return {
    src: getDirectusFileUrl(data),
    width,
    height,
    blurDataURL: metadata?.placeholder,
  };
};

export default function Image(props) {
  let mappedProps = props;
  const isDirectusImage =
    mappedProps.src && typeof mappedProps.src === 'object' && !mappedProps.src.src;
  const isAdminImage = mappedProps.adminImage;
  if (isDirectusImage || isAdminImage) {
    let { src, width, height, blurDataURL } = isAdminImage
      ? mappedProps.src
      : mapDirectusImage(mappedProps.src);
    if (width > 980) {
      const ratio = 980 / width;
      let newWidth = 980;
      let newHeight = Math.round(height * ratio);
      width = newWidth;
      height = newHeight;
    }

    const imageWidth = props.width || width;
    const imageHeight = props.height || height;
    mappedProps = {
      ...props,
      src,
      blurDataURL,
      // Only specify width and height if not specified already
      // Layout fill does not support fixed width/height
      width: imageWidth ?? (!props.fill ? imageWidth : null),
      height: imageHeight ?? (!props.fill ? imageHeight : null),
    };

    // if (mappedProps.width && mappedProps.width < 40) {
    // TODO: this is a test to see how it looks since there are strange blurs happening on some images.
    delete mappedProps.blurDataURL;
    delete mappedProps.placeholder;
    // }
  }

  let styleProps = {
    maxWidth: '100%',
    height: '100%',
  };

  if (props.fill) {
    delete mappedProps.width;
    delete mappedProps.height;
    styleProps = {};
  } else {
    if (props.width) {
      styleProps.width = `${props.width}px`;
    }

    if (props.height) {
      styleProps.height = `${props.height}px`;
    }
  }

  const imageExists = mappedProps && mappedProps.src && mappedProps.src !== '';

  return imageExists ? <NextImage {...mappedProps} style={styleProps} /> : null;
}
