import { kv } from '@vercel/kv';
import md5 from 'md5';
import fetcher from './fetcher';
import filterNullValues from './filter-null-values';
import * as gql from 'gql-query-builder';
import { DirectusPageType, FunnelPageType } from 'constant';

// Every 6 hours invalidate the keys
const kvExpiration = 60 * 60 * 6;

export const directusPageTypeMap = {
  [FunnelPageType.HOME_PAGE]: DirectusPageType.HOME_PAGE,
  [FunnelPageType.ARTICLE_PAGE]: DirectusPageType.ARTICLE_PAGE,
  [FunnelPageType.SALES_PAGE]: DirectusPageType.SALES_PAGE,
  [FunnelPageType.CHECKOUT_PAGE]: DirectusPageType.CHECKOUT_PAGE,
  [FunnelPageType.REFUND_POLICY_PAGE]: DirectusPageType.REFUND_POLICY_PAGE,
  [FunnelPageType.TERMS_AND_CONDITIONS_PAGE]: DirectusPageType.POLICY_PAGE,
  [FunnelPageType.PRIVACY_POLICY_PAGE]: DirectusPageType.POLICY_PAGE,
  [FunnelPageType.CONTACT_US_PAGE]: DirectusPageType.POLICY_PAGE,
  [FunnelPageType.PRESELL_PAGE]: DirectusPageType.PRESELL_PAGE,
  [FunnelPageType.OUR_STORY_PAGE]: DirectusPageType.OUR_STORY_PAGE,
};

export async function getProductCards(productCardIds) {
  // GraphQL query doesn't like empty array for query filter
  if (!productCardIds || productCardIds.length === 0) {
    return [];
  }

  const allProductCardsQuery = `
  fragment FileParts on directus_files {
    id
    title
    type
    width
    height
    filename_disk
    filesize
  }
  fragment FilePartsWithMetadata on directus_files {
    ...FileParts
    metadata
  }
  query GetProductCardsById($productCardIds: [GraphQLStringOrFloat!]!) {
    ProductCard(filter: { id: { _in: $productCardIds } }) {
      id
      title
      image {
        ...FilePartsWithMetadata
      }
      imageAlt
      description
      buttonText
      buttonColor
      titleColor
      textColor
      buttonTextColor
      buttonBorderColor
      buttonOnHoverColor
      buttonOnHoverBorderColor
      buttonOnHoverTextColor
      textFont {
        family
      }
      headingFont {
        family
      }
    }
  }
`;

  const gqlEndpoint = getDirectusURL('/graphql');
  const productCardsData = await fetcher(gqlEndpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.DIRECTUS_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: allProductCardsQuery,
      variables: {
        productCardIds,
      },
    }),
  });

  if (productCardsData.errors) {
    throw new Error(
      `Errors encountered querying article pages. Errors: ${JSON.stringify(
        productCardsData.errors
      )}`
    );
  }

  return productCardsData.data.ProductCard ?? [];
}

export async function getArticlePages(articleIds) {
  // GraphQL query doesn't like empty array for query filter
  if (!articleIds || articleIds.length === 0) {
    return [];
  }

  const allArticlesQuery = `
  fragment FileParts on directus_files {
    id
    title
    type
    width
    height
    filename_disk
    filesize
  }
  fragment FilePartsWithMetadata on directus_files {
    ...FileParts
    metadata
  }
  query GetPagesByIds($articleIds: [GraphQLStringOrFloat!]!) {
    ArticlePage(limit: -1, filter: { id: { _in: $articleIds } }) {
      id
      title
      tag {
        name
      }
      category {
        name
      }
      publishDate
      authorName
      mainHeading
      coverImage {
        ...FilePartsWithMetadata
      }
      tagBackgroundColor,
      tagTextColor
    }
  }
`;
  const gqlEndpoint = getDirectusURL('/graphql');
  const articlesData = await fetcher(gqlEndpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.DIRECTUS_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: allArticlesQuery,
      variables: {
        articleIds,
      },
    }),
  });

  if (articlesData.errors) {
    throw new Error(
      `Errors encountered querying article pages. Errors: ${JSON.stringify(articlesData.errors)}`
    );
  }

  return articlesData.data?.ArticlePage ?? [];
}

export async function getCachedArticlePages(articleIds) {
  const hashedName = hashedQuery(articleIds.join(','));
  const response = await kv.get(hashedName);

  if (!response) {
    const articlePages = await getArticlePages(articleIds);
    await kv.set(hashedName, articlePages, { ex: kvExpiration });

    return articlePages;
  }

  return response;
}

export function getDirectusURL(path) {
  return `${process.env.NEXT_PUBLIC_DIRECTUS_URL || 'http://localhost:1337'}${path}`;
}

export function getDirectusFileUrl(file) {
  if (!file) return null;

  const { filename_disk: fileName, filesize: fileSize } = file;
  if (fileName === null) {
    return null;
  }

  return `https://storage.googleapis.com/${process.env.NEXT_PUBLIC_GCS_BUCKET}/${fileName}?${fileSize}`;
}

/**
 *
 * @param {Object} options
 * @param {string} options.id The page's id
 */
export async function getPageData({ id, pageType, pageDataQuery }) {
  // Find the pages that match this id
  const gqlEndpoint = getDirectusURL('/graphql');
  const pagesData = await fetcher(gqlEndpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.DIRECTUS_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: pageDataQuery,
      variables: {
        id,
      },
    }),
  });

  if (pagesData.errors) {
    throw new Error(
      `Errors encountered querying Page ID: ${id} of type: ${pageType}. Errors: ${JSON.stringify(
        pagesData.errors
      )}`
    );
  }

  // Make sure we found something, otherwise throw
  if (!pagesData.data?.[pageType] || pagesData.data[pageType].length === 0) {
    throw new Error(`Cannot find page ID: ${id} of type: ${pageType}`);
  }

  // Return the first item since there should only be one result per id
  return filterNullValues(pagesData.data[pageType][0]);
}

export async function getCachedPageData({ id, pageType, pageDataQuery }) {
  const hashedName = hashedQuery({ id, pageType, pageDataQuery });
  const response = await kv.get(hashedName);

  if (!response) {
    const pageData = await getPageData({ id, pageType, pageDataQuery });
    await kv.set(hashedName, JSON.stringify(pageData), { ex: kvExpiration });

    return pageData;
  }

  return response;
}

export async function getPolicyPageData({ id }) {
  const pageType = DirectusPageType.POLICY_PAGE;
  const pageDataQuery = `
  query GetPages($id: GraphQLStringOrFloat!) {
    ${pageType}(filter: { id: { _eq: $id } }) {
      title
      content {
        type
        text
      }
    }
  }
  `;
  return getPageData({ id, pageType, pageDataQuery });
}

export async function getProductsData({ productIds }) {
  // GraphQL query doesn't like empty array for query filter
  if (!productIds || productIds.length === 0) {
    return [];
  }

  // Find the pages that match this id
  const gqlEndpoint = getDirectusURL('/graphql');

  const productsQuery = `
  fragment FileParts on directus_files {
    id
    title
    type
    width
    height
    filename_disk
    filesize
  }

  query GetProducts($productIds: [GraphQLStringOrFloat!]!) {
    Product(filter: { id: { _in: $productIds } }) {
      id
      image {
        ...FileParts
      }
    }
  }`;
  const productsData = await fetcher(gqlEndpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.DIRECTUS_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: productsQuery,
      variables: {
        productIds,
      },
    }),
  });

  if (productsData.errors) {
    throw new Error(
      `Errors encountered querying Product IDs: ${productIds}. Errors: ${JSON.stringify(
        productsData.errors
      )}`
    );
  }

  return productsData.data?.Product ?? [];
}

export function buildPageDataQuery(pageType, fields) {
  const pageQuery = gql.query(
    [
      {
        operation: `${pageType}(filter: { id: { _eq: $id } })`,
        fields,
      },
    ],
    null,
    { operationName: 'GetPages($id: GraphQLStringOrFloat!)' }
  );

  return `fragment FileParts on directus_files {
    id
    title
    type
    width
    height
    filename_disk
    filesize
  }
  fragment FilePartsWithMetadata on directus_files {
    ...FileParts
    metadata
  }
  ${pageQuery.query}`;
}
export async function searchArticlePages(searchTerm, articleIds, limit, offset) {
  // GraphQL query doesn't like empty array for query filter
  if (!searchTerm || articleIds.length === 0) {
    return [];
  }

  const searchArticleQuery = `
  fragment FileParts on directus_files {
    id
    title
    type
    width
    height
    filename_disk
    filesize
  }
  fragment FilePartsWithMetadata on directus_files {
    ...FileParts
    metadata
  }
    query SearchArticlePages($searchTerm: String!, $articleIds: [GraphQLStringOrFloat!]!, $limit: Int, $offset: Int) {
      ArticlePage(filter: {
        _and: [
          { id: { _in: $articleIds } },
          { _or: [
            { title: { _contains: $searchTerm } },
            { category: { name: { _contains: $searchTerm } } },
            { tag: { name: { _contains: $searchTerm } } },
            { mainHeading: { _contains: $searchTerm } },
            { sections: { item__ContentModule: { heading: { _contains: $searchTerm } } } },
            { sections: { item__ContentModule: { text: { _contains: $searchTerm } } } }
          ]}
        ]
      },
      limit: $limit, 
      offset: $offset
      ) {
        id
        title
        tag {
          name
        }
        category {
          name
        }
        publishDate
        authorName
        mainHeading
        coverImage {
          ...FilePartsWithMetadata
        }
        tagBackgroundColor
        tagTextColor
      }
      ArticlePage_aggregated(filter: {
        _and: [
          { id: { _in: $articleIds } },
          { _or: [
            { title: { _contains: $searchTerm } },
            { category: { name: { _contains: $searchTerm } } },
            { tag: { name: { _contains: $searchTerm } } },
            { mainHeading: { _contains: $searchTerm } },
            { sections: { item__ContentModule: { heading: { _contains: $searchTerm } } } },
            { sections: { item__ContentModule: { text: { _contains: $searchTerm } } } }
          ]}
        ]
      }) {
        count {
          id
        }
      }
    }
  `;

  const gqlEndpoint = getDirectusURL('/graphql');
  const searchResultsData = await fetcher(gqlEndpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.DIRECTUS_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: searchArticleQuery,
      variables: {
        searchTerm,
        articleIds,
        limit,
        offset,
      },
    }),
  });

  if (searchResultsData.errors) {
    throw new Error(
      `Errors encountered querying Article Pages. Errors: ${JSON.stringify(
        searchResultsData.errors
      )}`
    );
  }

  const totalCount = searchResultsData.data?.ArticlePage_aggregated[0]?.count?.id ?? 0;
  const searchResult = searchResultsData.data?.ArticlePage ?? [];
  return { totalCount, searchResult };
}

export function hashedQuery(queryParams) {
  return md5(JSON.stringify(queryParams));
}
