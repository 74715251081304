import { FunnelPageType, FooterLinks } from 'constant';

export const appendQueryParam = (path, key, value) => {
  const hasQuery = path?.includes('?');
  return `${path}${hasQuery ? '&' : '?'}${key}=${value}`;
};

export const buildFunnelPath = (internalFunnelPath, funnelId, additionalQueryParams = {}) => {
  const withFid = appendQueryParam(internalFunnelPath, 'fid', funnelId);
  return Object.keys(additionalQueryParams).reduce((currentPath, key) => {
    const value = additionalQueryParams[key];
    return appendQueryParam(currentPath, key, value);
  }, withFid);
};

export const buildImageUrl = imagePath => {
  if (!imagePath || imagePath.indexOf('http://') === 0 || imagePath.indexOf('https://') === 0) {
    return imagePath;
  }

  return `${window.location.origin}/${imagePath}`;
};

export const isSecure = () => window.location.protocol === 'https:';

export const buildFunnelPagePath = funnelPage => {
  if (!funnelPage) return null;

  const { type, path, slug } = funnelPage;
  switch (type) {
    case FunnelPageType.HOME_PAGE: {
      return '/home';
    }

    case FunnelPageType.CATEGORY_PAGE: {
      return `/category/${slug}/${path}`;
    }

    case FunnelPageType.ARTICLE_PAGE: {
      return `/articles/${path}`;
    }

    case FunnelPageType.SALES_PAGE: {
      return `/products/${path}`;
    }

    case FunnelPageType.CHECKOUT_PAGE: {
      return `/checkouts/${path}`;
    }

    case FunnelPageType.UPSELL_PAGE: {
      return `/upsells/${path}`;
    }

    case FunnelPageType.PRESELL_PAGE: {
      return `/${path}`;
    }

    case FunnelPageType.REFUND_POLICY_PAGE: {
      return FooterLinks.REFUND_POLICY;
    }

    case FunnelPageType.TERMS_AND_CONDITIONS_PAGE: {
      return FooterLinks.TERMS_AND_CONDITIONS;
    }

    case FunnelPageType.PRIVACY_POLICY_PAGE: {
      return FooterLinks.PRIVACY_POLICY;
    }

    case FunnelPageType.CONTACT_US_PAGE: {
      return FooterLinks.CONTACT_US;
    }

    case FunnelPageType.OUR_STORY_PAGE: {
      return FooterLinks.OUR_STORY;
    }

    default: {
      throw new Error(`buildFunnelPagePath: Invalid funnel page type ${type}`);
    }
  }
};

export const buildFunnelPageUrl = ({ rootDomain, funnelPage, fid }) => {
  return buildFunnelPath(`https://${rootDomain}${buildFunnelPagePath(funnelPage)}`, fid, {
    pid: funnelPage.id,
  });
};

export const buildApiBaseUrl = ({ rootDomain }) => {
  // Development and staging environment case.
  if (process.env.NEXT_PUBLIC_API_BASE_URL) {
    return process.env.NEXT_PUBLIC_API_BASE_URL;
  }

  // Production environment Uncoil brand case.
  if (rootDomain === 'production-generic.vercel.app') {
    return 'https://api.velocity.mttr.ca';
  }

  // Production environment case for regular brands.
  return `https://api.${rootDomain}`;
};
