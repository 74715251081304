import { useFunnelData } from './funnel-data-context';

function buildFontOverrides({ fontFamily, weights }, prefix) {
  return `
    --${prefix}-font-family: ${fontFamily};
    --${prefix}-weight-normal: ${weights.normal};
    --${prefix}-weight-bold: ${weights.bold};
    --${prefix}-weight-black: ${weights.black};
  `;
}

export function useHeadingFontOverrides(family) {
  const { fonts } = useFunnelData();
  if (!family) return;

  const fontFamily = fonts[family];
  if (!fontFamily) return;
  
  return buildFontOverrides(fontFamily, 'heading');
}

export function useTextFontOverrides(family) {
  const { fonts } = useFunnelData();
  if (!family) return;

  const fontFamily = fonts[family];
  if (!fontFamily) return;

  return buildFontOverrides(fontFamily, 'text');
}
